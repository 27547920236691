/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
    background-color: #333;
    color: #fff;
  }
  
  .logo {
    margin: 0;
  }
  
  .nav {
    /* Add specific styles for your nav if needed */
  }
  
  .navList {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navItem {
    margin-left: 20px;
  }
  
  .navLink {
    color: #fff;
    text-decoration: none;
    /* Add ':hover' state styles here if you like */
  }
  